import React from "react";
import "./styles/TeamCard.scss";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function TeamCard({ member }) {
  return (
    <div className={`team-card box mb-0 is-clipped p-0 is-relative`}>
      <a
        className="unit-link"
        href={member.social}
        target="_blank"
        rel="follow"
        aria-hidden="true"
        tabIndex="-1"
      >
        &nbsp;
      </a>
      <div className="card is-image-card" style={{ boxShadow: "none" }}>
        <div className="">
          <figure className="image">
            <PreviewCompatibleImage imageInfo={member.image} />
          </figure>
        </div>
      </div>
      <div className="is-content has-text-centered py-5 is-flex is-justify-content-space-between is-align-items-center px-4">
        <h2 className="is-size-3 has-text-weight-semibold">{member.name}</h2>
        <FontAwesomeIcon icon={faInstagram} size="xl" />
      </div>
    </div>
  );
}
