import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import TeamCard from "../components/TeamCard";
import Social from "../components/Social";
import "../components/styles/TeamPage.scss";
import BookingButton from "../components/BookingButton";

export const TeamPageTemplate = ({ title, subheading, team }) => {
  return (
    <>
      <div>
        <h1 style={{ display: "none" }}>
          Ms. Tang’s Nails and Spa Team Gallery | Nail salon | Santa Maria, CA
          93455
        </h1>
        <section className="banner is-medium py-5 is-flex is-align-items-center is-justify-content-center">
          <h1 className="has-text-centered has-text-white has-text-weight-bold banner-text">
            {title}
          </h1>
        </section>
        <section className="team-card-container container">
          {team.map((member, index) => {
            return <TeamCard key={index} member={member} />;
          })}
        </section>
        <section className="social bg-alt section is-medium">
          <Social />
        </section>
        <div className="fab">
          <Link to="/booking/">
            <BookingButton />
          </Link>
        </div>
      </div>
    </>
  );
};

TeamPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  team: PropTypes.array,
};

const TeamPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TeamPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        team={frontmatter.team}
      />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TeamPage;

export const pageQuery = graphql`
  query TeamPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "team-page" } }) {
      frontmatter {
        title
        subheading
        team {
          name
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          social
        }
      }
    }
  }
`;
